<div class="onboarding-children-step-container">
  <div class="row">
    <div class="col-12">
      <div class="block-title font-size-h4 fw-400 mb-20">Hast du Kinder?</div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div style="height: 41px"></div>
    </div>
  </div>

  <div class="middle-content mb-15 pt-50">
    <div class="row">
      <div class="col-md-12 d-flex align-items-center justify-content-center">
        <input
          type="tel"
          class="form-control form-control-lg w-25 text-center"
          #childrenAmount
          id="childrenAmount"
          name="childrenAmount"
          placeholder="{{ !personProfile?.childrenAmount ? '0' : personProfile.childrenAmount }}"
          autocomplete="off"
          (input)="slideChildrenAmountChanged()"
        />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6 text-left py-2">
      <button
        type="button"
        class="btn btn-rounded theme-secondary-color pointer-cursor"
        (click)="triggerPreviousStep.emit()"
      >
        <i class="bi bi-arrow-left mr-2"></i> Zur&uuml;ck
      </button>
    </div>

    <div class="col-6 text-right">
      <button type="button" class="btn btn-rounded btn-purple btn-modal" (click)="triggerNextStep.emit()">
        Weiter
        <i class="bi bi-arrow-right ml-2"></i>
      </button>
    </div>
  </div>
</div>
