import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PreciousMetalDetails, UpdatePreciousMetalDetails } from '../model';
import { WealthService } from './wealth.service';

@Injectable({
  providedIn: 'root',
})
export class WealthPreciousMetalService {
  private basePath = 'precious-metal';

  constructor(private wealthService: WealthService) {}

  public getPreciousMetalById(id: number): Observable<PreciousMetalDetails> {
    return this.wealthService.getEntityById<PreciousMetalDetails>(this.basePath, id);
  }

  public updatePreciousMetalById(id: number, data: UpdatePreciousMetalDetails): Observable<PreciousMetalDetails> {
    const transform = {
      ...data,
      numberOfImageToAdd: Array.isArray(data.numberOfImageToAdd)
        ? data.numberOfImageToAdd.length
        : data.numberOfImageToAdd,
    } as any;

    return this.wealthService.updateEntityById<PreciousMetalDetails, UpdatePreciousMetalDetails>(
      this.basePath,
      id,
      transform,
    );
  }

  public deletePreciousMetalById(id: number): Observable<void> {
    return this.wealthService.deleteEntityById(this.basePath, id);
  }

  public uploadImageToPreciousMetalUrl(url: string, file: File, id: number): Observable<any> {
    return this.wealthService.uploadImageToEntityUrl(url, file, id, this.basePath);
  }
}
