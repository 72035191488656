import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MobilityDetails, UpdateMobilityDetails } from '../model';
import { WealthService } from './wealth.service';

@Injectable({
  providedIn: 'root',
})
export class WealthVehicleService {
  private basePath = 'vehicle';

  constructor(private wealthService: WealthService) {}

  public getVehicleById(id: number): Observable<MobilityDetails> {
    return this.wealthService.getEntityById<MobilityDetails>(this.basePath, id);
  }

  public updateVehicleById(id: number, data: UpdateMobilityDetails): Observable<MobilityDetails> {
    const transform = {
      ...data,
      numberOfImageToAdd: Array.isArray(data.numberOfImageToAdd)
        ? data.numberOfImageToAdd.length
        : data.numberOfImageToAdd,
    } as any;

    return this.wealthService.updateEntityById<MobilityDetails, UpdateMobilityDetails>(this.basePath, id, transform);
  }

  public deleteVehicleById(id: number): Observable<void> {
    return this.wealthService.deleteEntityById(this.basePath, id);
  }

  public uploadImageToVehicleUrl(url: string, file: File, id: number): Observable<any> {
    return this.wealthService.uploadImageToEntityUrl(url, file, id, this.basePath);
  }
}
