import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DeviceDetectorService } from 'ngx-device-detector';

import { UserSidebarModalComponent } from './user-sidebar-modal/user-sidebar-modal.component';

import { BankCardInfoService } from '../shared/global-store';

import { BankAccount } from '../shared/model';

import WithSafeArea from '../shared/utils/WithSafeArea';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class LayoutComponent extends WithSafeArea(Object) implements OnInit {
  protected innerWidth: number;

  protected firstLayer: boolean;
  protected hideHeader: boolean = false;
  protected deepDiveTitle: string;
  protected isOpen: boolean = false;

  protected deviceInfo: string = null;
  protected userAgent: any;

  // protected isMobileDevice = 'native' in window;

  constructor(
    localeService: BsLocaleService,
    private readonly bankCardInfoService: BankCardInfoService,
    private readonly location: Location,
    protected readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly bsModalService: BsModalService,
    private readonly deviceService: DeviceDetectorService,
  ) {
    super();
    localeService.use('de');
    this.windowSize();
    this.deviceInfo = this.deviceService.getDeviceInfo().device.toLowerCase();
  }

  public ngOnInit(): void {
    this.changeActiveNavigationView(this.location);

    this.router.events.subscribe((event): void => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/') {
          this.router.navigateByUrl('dashboard');
        } else {
          this.changeActiveNavigationView(this.location);
        }
      }
    });

    if (this.router.url === '/') {
      this.router.navigateByUrl('dashboard');
    }
  }

  protected setCorrectSafeAreaByDevice(): number {
    if (this.deviceInfo === 'android') {
      if (this.safeAreaTop > 0 && !this.hideHeader) {
        return this.safeAreaTop + 80;
      } else {
        return 70;
      }
    } else if (this.deviceInfo === 'iphone') {
      if (this.safeAreaTop > 0 && !this.hideHeader) {
        return this.safeAreaTop + 70;
      } else {
        return 70;
      }
    } else {
      if (this.safeAreaTop > 0 && !this.hideHeader) {
        return this.safeAreaTop + 60;
      } else {
        return this.innerWidth <= 650 ? 70 : 0;
      }
    }
  }

  @HostListener('window:resize')
  protected onResize(): void {
    this.windowSize();
  }

  protected showMobileUserSidebar(sidebarState: boolean): void {
    if (this.innerWidth <= 650) {
      this.bsModalService.show(UserSidebarModalComponent, {});
    } else {
      this.isOpen = sidebarState;
    }
  }

  protected hideMobileUserSidebar(sidebarState: boolean): void {
    this.isOpen = sidebarState;
  }

  private windowSize(): void {
    this.innerWidth = window.innerWidth;
  }

  private changeActiveNavigationView(location: Location): void {
    let paramName: string = '';

    this.route.queryParams.subscribe((params: Params): void => {
      paramName = params['name'];
    });

    let [pathname, search = ''] = location.path(false).split('?');

    if (!pathname) {
      pathname = this.router.url;
    }

    if (pathname.includes('vinhub') && search.includes('param=%')) {
      this.firstLayer = window.innerWidth > 800 && window.innerWidth < 950;
    } else {
      this.firstLayer =
        pathname.includes('dashboard') ||
        pathname.includes('contracts/contracts-manager') ||
        pathname.includes('contracts/contracts-advices') ||
        pathname.includes('bank-card-manager/bank-card-overview') ||
        pathname.includes('bank-card-manager/bank-card-analysis') ||
        pathname.includes('wealth/wealth-overview') ||
        pathname.includes('/person-profile') ||
        pathname.includes('/settings') ||
        pathname.includes('/document-upload-section') ||
        pathname.includes('/notifications') ||
        pathname.includes('/confidential-access') ||
        pathname.includes('/bank-accounts-overview') ||
        (pathname.includes('vinhub') && search.includes('param=')) ||
        (pathname.includes('vinhub') && !search);
    }

    this.hideHeader = pathname.includes('vinhub');

    if (pathname.includes('dashboard')) this.deepDiveTitle = 'Übersicht';
    if (pathname.includes('contracts')) this.deepDiveTitle = 'Verträge';
    if (pathname.includes('bank-card-manager/bank-card-overview')) this.deepDiveTitle = 'Konten';
    if (pathname.includes('bank-card-manager/bank-card-analysis')) this.deepDiveTitle = 'Analysen';
    if (pathname.includes('vinhub')) this.deepDiveTitle = 'Chats';
    if (pathname.includes('vinsafes')) this.deepDiveTitle = 'Vinsafes eröffnen';
    if (pathname.includes('finapi-connection')) this.deepDiveTitle = 'Bank verbinden';
    if (pathname.includes('bank-accounts-overview')) this.deepDiveTitle = 'Meine Bankzugänge';
    if (pathname.includes('settings')) this.deepDiveTitle = 'Persönliche Daten';
    if (pathname.includes('/document-upload-section')) this.deepDiveTitle = 'Meine Dokumente';
    if (pathname.includes('notifications')) this.deepDiveTitle = 'Benachrichtigungen';
    if (pathname.includes('change-password')) this.deepDiveTitle = 'Passwort ändern';
    if (pathname.includes('confidential-access')) this.deepDiveTitle = 'Privatsphäre';
    if (pathname.includes('/alerts')) this.deepDiveTitle = 'Kontoalarm';
    if (pathname.includes('/tags-manager')) this.deepDiveTitle = 'Tags manager';
    if (pathname.includes('/single-analyse')) this.deepDiveTitle = 'Kontoanalyse';
    if (pathname.includes('person-profile')) this.deepDiveTitle = 'Finanzprofil Daten';
    if (pathname.includes('/account-transaction-details/')) this.deepDiveTitle = 'Kontoumsätze';
    if (pathname.includes('/contract-creation')) this.deepDiveTitle = 'Vertrag hinzufügen';
    if (pathname.includes('/wealth/wealth-overview')) this.deepDiveTitle = 'Vermögen';
    if (pathname.includes('/wealth-creation')) this.deepDiveTitle = 'Vermögen hinzufügen';

    if (pathname.includes('/contract-details')) {
      this.deepDiveTitle = paramName;
    }

    if (
      pathname.includes('/bank-card-manager/') &&
      !pathname.includes('alerts') &&
      !pathname.includes('/single-analyse') &&
      !pathname.includes('/tags-manager') &&
      !pathname.includes('/wealth') &&
      !this.firstLayer
    ) {
      this.deepDiveTitle = paramName;

      this.bankCardInfoService.getUpdatedBankCard().subscribe({
        next: (value: BankAccount): void => {
          if (value && value.name) {
            this.deepDiveTitle = value.name;
          }
        },
        error: (error): void => {
          console.log(error);
        },
      });
    }
  }
}
