import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { WealthAttachments } from '../model';
import WithSubscription from '../utils/WithSubscriptions';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root',
})
export class WealthService extends WithSubscription(class {}) {
  private apiEndpoint = `${environment.apiEndpoint}/wealth`;

  private attachmentsSubject = new BehaviorSubject<any[]>([]);
  public attachments$ = this.attachmentsSubject.asObservable();
  public filteredImages$ = new BehaviorSubject<{ attachmentUrl: string; id: string }[]>([]);

  public loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(
    private http: HttpClient,
    private modalService: BsModalService,
  ) {
    super();
  }

  public getAggregatedAssets(userId: string): Observable<any> {
    return this.http.get<any>(`${this.apiEndpoint}/aggregated-assets/${userId}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        console.error('Error fetching aggregated assets:', error);
        return error;
      }),
    );
  }

  public saveWealthData(wealthType: string, data: any): Observable<any> {
    const endpoint = this.getEndpoint(wealthType);
    const dataToSend = {
      ...data,
      numberOfImageToAdd: data.numberOfImageToAdd.length,
    };
    return this.http.post<any>(endpoint, dataToSend).pipe(
      map((res: any) => res),
      catchError((error) => {
        console.error('Error saving wealth data:', error);
        return throwError(() => new Error('Failed to save wealth data'));
      }),
    );
  }

  private getEndpoint(wealthType: string): string {
    const endpoints = {
      'real-estate': `${this.apiEndpoint}/real-estate`,
      vehicle: `${this.apiEndpoint}/vehicle`,
      jewelry: `${this.apiEndpoint}/jewelry`,
      'precious-metal': `${this.apiEndpoint}/precious-metal`,
      art: `${this.apiEndpoint}/art`,
      other: `${this.apiEndpoint}/other`,
      // Add more wealth types and their corresponding endpoints here
    };

    const endpoint = endpoints[wealthType];
    if (!endpoint) {
      throw new Error(`Unknown wealth type: ${wealthType}`);
    }

    return endpoint;
  }

  public getEntityById<T>(basePath: string, id: number): Observable<T> {
    return this.http
      .get<T>(`${this.apiEndpoint}/${basePath}/${id}`)
      .pipe(tap((res: any) => this.updateAttachments(res.attachments)));
  }

  public updateEntityById<T, U>(basePath: string, id: number, data: U): Observable<T> {
    return this.http
      .put<T>(`${this.apiEndpoint}/${basePath}/${id}`, data)
      .pipe(tap((res: any) => this.updateAttachments(res.attachments)));
  }

  public deleteEntityById(basePath: string, id: number): Observable<void> {
    const confirmationModalRef = this.modalService.show(ConfirmationModalComponent, {});
    confirmationModalRef.content.confirmationTitle = 'Bestätigung';
    confirmationModalRef.content.confirmationMessage =
      'Bist du dir sicher, dass du diesen Vermögensgegenstand löschen möchtest?';
    confirmationModalRef.content.danger = true;

    return new Observable<void>((observer) => {
      confirmationModalRef.content.action
        .pipe(
          tap((value: boolean) => {
            if (value === true) {
              this.http
                .delete<void>(`${this.apiEndpoint}/${basePath}/${id}`)
                .pipe(
                  tap(() => {
                    observer.next();
                    observer.complete();
                  }),
                  catchError((error) => {
                    observer.error(error);
                    return of();
                  }),
                  finalize(() => {
                    confirmationModalRef.hide();
                  }),
                )
                .subscribe();
            } else {
              confirmationModalRef.hide();
              observer.complete();
            }
          }),
        )
        .subscribe();
    });
  }

  public uploadImageToEntityUrl(url: string, file: File, id: number, basePath: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': file.type,
      'x-amz-acl': 'private',
    });

    return this.http.put(url, file, { headers, observe: 'response', responseType: 'text' }).pipe(
      switchMap(() => this.getEntityById(basePath, id)),
      tap((res: any) => this.updateAttachments(res.attachments)),
    );
  }

  public updateAttachments(attachments: WealthAttachments[]): void {
    const allAttachments = attachments.map((attachment) => ({
      id: attachment.id,
      mainAsset: attachment.mainAsset,
      attachments: attachment.attachments,
    }));
    this.attachmentsSubject.next(allAttachments);
  }

  public filterImagesByType(type: 'SMALL' | 'MEDIUM' | 'FULL'): void {
    this.listenToSubject(
      this.attachments$.pipe(
        map((attachments: any[]) =>
          attachments
            .map((attachment) => {
              let downloadUrl = '';

              if (type === 'SMALL') {
                downloadUrl =
                  attachment.attachments?.thumbnailSmall?.downloadUrl || attachment.mainAsset?.downloadUrl || '';
              } else if (type === 'MEDIUM') {
                downloadUrl =
                  attachment.attachments?.thumbnailMedium?.downloadUrl || attachment.mainAsset?.downloadUrl || '';
              } else if (type === 'FULL') {
                downloadUrl = attachment.mainAsset?.downloadUrl || '';
              }

              return {
                attachmentUrl: downloadUrl,
                id: attachment.id.toString(),
              };
            })
            .filter((image) => !!image.attachmentUrl),
        ),
      ),
      (filteredImages) => {
        this.filteredImages$.next(filteredImages);
      },
    );
  }
}
