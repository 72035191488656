<div class="analyse-share-modal-container" [style.padding-bottom.px]="safeAreaBottom">
  <div class="d-flex d-md-none justify-content-center pt-10" (click)="bsModalRef.hide()">
    <img src="https://static-assets.vinlivt.de/icons/misc/mobile-gray-line.png" alt="line" />
  </div>

  <div class="modal-body">
    @if (backgroundInformation) {
      <div class="row">
        <div class="col-12">
          <!-- Background Information Block -->
          <app-background-information
            [companyId]="company?.id"
            [consentType]="consentType"
            (hideBackgroundInformation)="showBackGroundInformation($event, null)"
          ></app-background-information>
        </div>
      </div>
    } @else {
      <div class="row">
        <div class="col-12 text-center mb-15">
          <img
            src="https://static-assets.vinlivt.de/icons/premium/real/640x640/Chart%203.png"
            height="75"
            alt="analyse"
          />
        </div>

        <div class="col-12 text-center">
          <div class="text-20 font-w700 theme-color">Finanzreport teilen</div>
        </div>

        <div class="col-12 text-center">
          <div class="text-16 theme-color">
            Hier kannst du deine Finanzanalyse mit deinem persönlichen Berater teilen.
          </div>
        </div>
      </div>

      <div class="row overflow-scroll my-20">
        @for (bank of copyBankAccountList; track bank.id; let last = $last) {
          <div class="col-12 mb-15" [ngClass]="{ 'mb-0': last }">
            <div class="text-16 theme-color">{{ bank.bankName }}</div>

            <div class="row">
              @for (card of bank.accounts; track card.id) {
                <div class="col-12 d-flex align-items-center justify-content-between py-10">
                  <div class="d-flex align-items-center">
                    <button
                      class="btn p-0 btn-circle d-flex align-items-center justify-content-center gray-circle-small mr-10"
                    >
                      @if (card?.bankIconUrl) {
                        <img
                          class="bank-icon"
                          width="30"
                          height="30"
                          [src]="card.bankIconUrl"
                          [alt]="card.bankIconUrl"
                        />
                      } @else {
                        <i class="bi bi-bank theme-color" style="font-size: 28px"></i>
                      }
                    </button>

                    <div class="d-flex flex-column">
                      <div class="text-16 theme-color">{{ card?.name }}</div>

                      <div class="text-secondary text-12">{{ card?.iban }}</div>
                    </div>
                  </div>

                  <!-- Toggler -->
                  <label class="css-control css-control-primary css-switch">
                    <input
                      [(ngModel)]="card.isSharable"
                      [checked]="card.isSharable"
                      [ngModelOptions]="{ standalone: true }"
                      (change)="onValueChange($event)"
                      type="checkbox"
                      class="css-control-input"
                    />
                    <span class="css-control-indicator"></span>
                  </label>
                </div>
              }
            </div>
          </div>
        }
      </div>

      <div class="row mb-15">
        <div class="col-12">
          <div class="theme-color text-center" style="font-size: 11px">
            Durch Klicken auf "Jetzt teilen" erklärst du dich mit den
            <a
              class="text-purple"
              href="javascript:void(0);"
              (click)="showBackGroundInformation(true, consent.PARTNER_PRIVACY_CLIENT)"
              >Datenschutzinformationen</a
            >
            gegenüber der
            <b>{{ company?.name }}</b>
            freiwillig einverstanden. Das kannst du jederzeit widerrufen.
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-4 col-lg-6 px-0">
          <button class="btn btn-outline" (click)="bsModalRef.hide()">
            <span class="text-purple">Später</span>
          </button>
        </div>

        <div class="col-8 col-lg-6 text-right">
          <button
            class="btn btn-purple btn-rounded"
            [disabled]="shareRequest || !valueChanged"
            (click)="analyseShareWithAdvisor()"
          >
            <span>Jetzt teilen</span>

            @if (shareRequest) {
              <span class="ml-10">
                <div class="spinner-border text-white" role="status" style="width: 14px; height: 14px">
                  <span class="sr-only">Bitte warten...</span>
                </div>
              </span>
            }
          </button>
        </div>
      </div>
    }
  </div>
</div>
