import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './app-layout.component';

const layoutRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('../pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
        // data: { animation: 'One' },
      },
      {
        path: 'bank-card-manager',
        loadChildren: () =>
          import('../pages/bank-card-manager/bank-card-manager.module').then((m) => m.BankCardManagerModule),
        // data: { animation: 'Three' },
      },
      {
        path: 'person-profile',
        loadChildren: () =>
          import('../pages/profile/profile-data/profile-data.module').then((m) => m.ProfileDataModule),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('../pages/profile/notifications/notifications.module').then((m) => m.NotificationsModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('../pages/profile/settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'document-upload-section',
        loadChildren: () =>
          import('../pages/profile/document-upload-section/document-upload-section.module').then(
            (m) => m.DocumentUploadSectionModule,
          ),
      },
      {
        path: 'confidential-access',
        loadChildren: () =>
          import('../pages/profile/confidential-access/confidential-access.module').then(
            (m) => m.ConfidentialAccessModule,
          ),
      },
      {
        path: 'bank-accounts-overview',
        loadChildren: () =>
          import('../pages/profile/bank-accounts-overview/bank-accounts-overview.module').then(
            (m) => m.BankAccountsOverviewModule,
          ),
      },
      {
        path: 'contracts',
        loadChildren: () => import('../pages/contracts/contracts.module').then((m) => m.ContractsModule),
        // data: { animation: 'Two' },
      },

      {
        path: 'vinhub',
        loadChildren: () => import('../pages/vinhub/vinhub.module').then((m) => m.VinhubModule),
        // data: { animation: 'Four' },
      },
      {
        path: 'vinsafes',
        loadChildren: () => import('../pages/vinsafes/vinsafes.module').then((m) => m.VinsafesModule),
      },
      {
        path: 'finapi-connection',
        loadChildren: () =>
          import('../pages/finapi-connection/finapi-connection.module').then((m) => m.FinapiConnectionModule),
      },
      {
        path: 'wealth',
        loadChildren: () => import('../pages/wealth/wealth.module').then((m) => m.WealthModule),
      },
    ],
  },
];

export const ROUTES = RouterModule.forChild(layoutRoutes);
