import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UploadStateService {
  isLoading = signal(false);

  setLoadingState(state: boolean) {
    this.isLoading.set(state);
  }
}
