import { Component, EventEmitter, Inject, isDevMode, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { WebSocketService } from '@vinlivt/websocket';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AUTH_SERVICE_TOKEN, AuthService, VinhubService } from '../../shared/service';

import { VinhubMessage } from '../../shared/model';

import WithSubscription from '../../shared/utils/WithSubscriptions';
import WithSafeArea from '../../shared/utils/WithSafeArea';

@Component({
  selector: 'app-mobile-bottom-navigation',
  templateUrl: './mobile-bottom-navigation.component.html',
  styleUrls: ['./mobile-bottom-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MobileBottomNavigationComponent extends WithSafeArea(WithSubscription(Object)) implements OnInit {
  @Output() open = new EventEmitter<any>();

  protected unreadMessages: number;
  protected isDev: boolean = false;
  protected deviceInfo: string = null;

  constructor(
    protected readonly router: Router,
    private readonly webSocketService: WebSocketService,
    private readonly vinhubService: VinhubService,
    private readonly deviceService: DeviceDetectorService,
    @Inject(AUTH_SERVICE_TOKEN) private readonly authService: AuthService,
  ) {
    super();
    this.deviceInfo = this.deviceService.getDeviceInfo().device.toLowerCase();
  }

  protected setCorrectSafeAreaByDevice(): number {
    if (this.deviceInfo === 'android') {
      return this.safeAreaBottom;
    } else if (this.deviceInfo === 'iphone') {
      return this.safeAreaBottom - 16;
    } else if (this.deviceInfo === 'htc') {
      return 20;
    } else {
      return 0;
    }
  }

  public ngOnInit(): void {
    this.listenForNewMessages();
    this.loadStatistics();

    if (isDevMode()) {
      this.isDev = true;
    }
  }

  private messageArrivedPlaySound(): void {
    const audio: HTMLAudioElement = new Audio();
    audio.src = 'assets/sounds/message-ring.mp3';
    audio.load();
    audio.play();
  }

  private loadStatistics(): void {
    this.vinhubService.checkUnreadMessages().subscribe({
      next: (data: number): void => {
        this.unreadMessages = data;
      },
      error: (error): void => {
        console.log(error);
      },
    });

    // this.chatMessageService.countTotalUnreadMessages().subscribe((unreadMessages: number): void => {
    //   this.unreadMessages = unreadMessages;
    // });
  }

  private listenForNewMessages(): void {
    this.listenToSubject(
      this.webSocketService.subscribe<number>('chat/changed-unread-count'),
      (messageCount: number): void => {
        this.unreadMessages = messageCount;
      },
    );

    this.listenToSubject(
      this.webSocketService.subscribe<VinhubMessage>('chat/new-message'),
      (message: VinhubMessage): void => {
        if (message.authorId !== this.authService.userId) {
          this.unreadMessages++;
          this.messageArrivedPlaySound();
        }
      },
    );
  }
}
