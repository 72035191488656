import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import WithSafeArea from '../../utils/WithSafeArea';
import { DeviceDetectorService } from 'ngx-device-detector';

export type MediaType = 'IMAGE' | 'PDF' | 'OTHER';

@Component({
  standalone: true,
  selector: 'app-media-preview-base-modal-container',
  encapsulation: ViewEncapsulation.None,
  template: '',
})
export abstract class MediaPreviewBaseModalComponent extends WithSafeArea(Object) implements OnDestroy {
  public deviceInfo: string;

  public filename: string;
  public size: number;

  protected blobUrl: string | null = null;
  protected fileBytes: Uint8Array | null = null;
  protected downloadInProgress = false;
  protected pdfLoaded = false;

  private mimeType: string | null = null;
  private shouldRevokeBlobUrl = false;

  protected constructor(
    public readonly modalRef: BsModalRef,
    private deviceService: DeviceDetectorService,
  ) {
    super();
    this.deviceInfo = this.deviceService.getDeviceInfo().device.toLowerCase();
  }

  public _type: MediaType;

  public get type(): MediaType {
    return this._type;
  }

  public set type(value: MediaType) {
    this._type = value;

    this.cleanBlobUrl();
    this.downloadFileIfNecessary();
  }

  private _downloadUrl: string;

  public get downloadUrl(): string {
    return this._downloadUrl;
  }

  public set downloadUrl(value: string) {
    this._downloadUrl = value;

    this.cleanBlobUrl();
    this.downloadFileIfNecessary();
  }

  protected get sizeFormatted(): string {
    if (this.size) {
      if (this.size < 1024) {
        return `${this.size} B`;
      } else if (this.size < 1024 * 1024) {
        return `${(this.size / 1024).toFixed(2)} KB`;
      } else {
        return `${(this.size / (1024 * 1024)).toFixed(2)} MB`;
      }
    } else {
      return '';
    }
  }

  public static getTypeFromExtension(extension: string): MediaType {
    switch (extension) {
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'bmp':
      case 'webp':
      case 'heic':
        return 'IMAGE';
      case 'pdf':
        return 'PDF';
      default:
        return 'OTHER';
    }
  }

  public getSafeAreaBottom(): number {
    if (this.deviceInfo === 'android') {
      return this.safeAreaBottom;
    } else if (this.deviceInfo === 'iphone') {
      return this.safeAreaBottom - 16;
    } else {
      return 0;
    }
  }

  public ngOnDestroy(): void {
    this.cleanBlobUrl();
  }

  protected async download(): Promise<void> {
    await this.downloadFile();

    if ('native' in window) {
      const base64 = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () =>
          resolve((reader.result as string).substring((reader.result as string).indexOf(',') + 1));
        reader.onerror = () => reject(reader.error);
        reader.readAsDataURL(new Blob([this.fileBytes]));
      });
      await window.native.shareFile(base64, this.mimeType, this.filename);
    } else {
      const anchor = document.createElement('a');
      anchor.href = this.blobUrl;
      anchor.download = this.filename;
      anchor.click();
    }
  }

  private async downloadFileIfNecessary(): Promise<void> {
    if (this.type === 'IMAGE' || this.type === 'PDF') {
      await this.downloadFile();
    }
  }

  private async downloadFile(): Promise<void> {
    if (this.downloadInProgress || this.blobUrl) {
      return;
    }

    if (!this.downloadUrl) {
      // console.error('Download URL is undefined or null.');
      return;
    }

    this.downloadInProgress = true;
    try {
      let blob: Blob;

      if (this.downloadUrl.startsWith('blob:')) {
        // for BLOBs
        this.blobUrl = this.downloadUrl;
        this.shouldRevokeBlobUrl = false;
        blob = await (await fetch(this.downloadUrl)).blob();

        this.fileBytes = new Uint8Array(await blob.arrayBuffer());
      } else {
        // regular URLS
        // const response = await fetch(this.downloadUrl);
        // if (!response.ok) {
        //   throw new Error('Network response was not ok');
        // }
        // blob = await response.blob();
        // this.blobUrl = URL.createObjectURL(blob);

        this.blobUrl = this._downloadUrl;
        this.shouldRevokeBlobUrl = true;
      }

      this.mimeType = blob.type;
    } catch (error) {
      console.error('Download failed:', error);
    } finally {
      this.downloadInProgress = false;
    }
  }

  private cleanBlobUrl(): void {
    if (this.blobUrl) {
      if (this.shouldRevokeBlobUrl) {
        URL.revokeObjectURL(this.blobUrl);
      }
      this.blobUrl = null;
    }
  }
}
