import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { BackgroundInformationComponent } from '../consents/background-information/background-information.component';

import { ModalsSafeAreaService } from '../../global-store';
import { OpenBankingService } from '../../service';

import { Advisor, BankAccount, BankConnectionDto, Company, Person, UpdateAccountListModel } from '../../model';

import { ConsentTypeEnum } from '../../enum';

@Component({
  standalone: true,
  selector: 'app-analyse-share-modal',
  templateUrl: 'analyse-share-modal.component.html',
  styleUrls: ['analyse-share-modal.component.scss'],
  imports: [CommonModule, FormsModule, BackgroundInformationComponent],
})
export class AnalyseShareModalComponent implements OnInit {
  @Output() triggerSave = new EventEmitter<BankConnectionDto[]>();

  public safeAreaBottom: number;

  public copyBankAccountList: BankConnectionDto[] = []; // Reference from the parent
  public bankAccountList: BankConnectionDto[] = []; // Temporary copy for changes

  public blurredAmountState: string;

  public shareRequest: boolean = false;
  public valueChanged: boolean = false;

  public person: Person = new Person();
  public advisor: Advisor = new Advisor();
  public company: Company = new Company();

  public consentType: string;
  public backgroundInformation: boolean;

  constructor(
    protected bsModalRef: BsModalRef,
    private modalsSafeAreaService: ModalsSafeAreaService,
    private openBankingService: OpenBankingService,
    private toastrService: ToastrService,
  ) {}

  public ngOnInit(): void {
    this.safeAreaBottom = this.modalsSafeAreaService.getSafeAreaBottom();

    setTimeout((): void => {
      // Create a deep copy of the original data
      this.copyBankAccountList = JSON.parse(JSON.stringify(this.bankAccountList));
    }, 200);
  }

  protected showBackGroundInformation(value: boolean, consentType: ConsentTypeEnum): void {
    this.consentType = ConsentTypeEnum[consentType];
    this.backgroundInformation = value;
  }

  protected onValueChange(e): void {
    this.valueChanged = true;
  }

  protected analyseShareWithAdvisor(): void {
    this.shareRequest = true;

    const request: UpdateAccountListModel[] = [];

    this.copyBankAccountList.forEach((bank: BankConnectionDto): void => {
      bank.accounts.forEach((account: BankAccount): void => {
        request.push({
          accountId: account.id,
          companyId: this.company.id,
          personDetails: {
            id: this.person.id,
            uid: this.person.uid,
            firstName: this.person.firstname,
            lastName: this.person.lastname,
            profilePictureUrl: this.person.profilePictureUrl,
          },
          advisorDetails: {
            id: this.advisor.id,
            uuid: this.advisor.uid,
            firstName: this.advisor.firstName,
            lastName: this.advisor.lastName,
            profilePicture: this.advisor.profilePictureUrl,
          },
          accountData: {
            isSharable: account.isSharable,
          },
        });
      });
    });

    this.openBankingService.updateAccountList(request).subscribe((): void => {
      this.triggerSave.emit(this.copyBankAccountList);
      this.shareRequest = false;
      this.toastrService.info('Finanzreport teilen wurde aktualisiert!');
      this.bsModalRef.hide();
    });
  }

  protected readonly consent = ConsentTypeEnum;
}
