import { Injectable } from '@angular/core';
import { JewelryDetails, UpdateJewelryDetails } from '../model';
import { Observable } from 'rxjs';
import { WealthService } from './wealth.service';

@Injectable({
  providedIn: 'root',
})
export class WealthJewelryService {
  private basePath = 'jewelry';

  constructor(private wealthService: WealthService) {}

  public getJewelryById(id: number): Observable<JewelryDetails> {
    return this.wealthService.getEntityById<JewelryDetails>(this.basePath, id);
  }

  public updateJewelryById(id: number, data: UpdateJewelryDetails): Observable<JewelryDetails> {
    const transform = {
      ...data,
      numberOfImageToAdd: Array.isArray(data.numberOfImageToAdd)
        ? data.numberOfImageToAdd.length
        : data.numberOfImageToAdd,
    } as any;

    return this.wealthService.updateEntityById<JewelryDetails, UpdateJewelryDetails>(this.basePath, id, transform);
  }

  public deleteJewelryById(id: number): Observable<any> {
    return this.wealthService.deleteEntityById(this.basePath, id);
  }

  public uploadImageToJewelryUrl(url: string, file: File, id: number): Observable<any> {
    return this.wealthService.uploadImageToEntityUrl(url, file, id, this.basePath);
  }
}
